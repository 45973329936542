<template>
    <section>
            <img src="@/assets/images/import/encours.png" alt="">
            La page est en cours de construction
    </section>
</template>
<script>
export default {
    name:'inprogress'
}
</script>